import React, { useEffect, useState } from "react";
import { get } from "../../services/cache";
import config from "../../services/config";
import { useTranslation } from "react-i18next";
import GetPhoto from "./Photo/GetPhoto";
import Modal from "react-modal";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';


export const SHApprovalCard = (props) => {

    const userAccessToken = get("fedidLoginAccessToken");


    let typeOfApp = localStorage.getItem("typeOfApp");

    const [isExpanded, setIsExpanded] = useState(false);
    const [isApproval, setIsApproval] = useState(false);
    const [isReject, setIsReject] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [preview, setPreview] = useState(null);
    const [productInfo, setProductInfo] = useState();
    const [cacheResult, setCacheResult] = useState();
    const [size, setSize] = useState();
    const [years, setYears] = useState(props.productAge);
    const [selectedCondition, setSelectedCondition] = useState(props.productCondition);
    const [price, setPrice] = useState(props.productPrice);
    const [warning, setWarning] = useState(false);
    const [photoKey, setPhotoKey] = useState(1);
    const [imageListLength, setImageListLength] = useState(0);

    const { t } = useTranslation();



    const [descInfo, setDescInfo] = useState('');
    const [customerDesc, setCustomerDesc] = useState('');
    const [loading, setLoading] = useState(null);
    const [error, setError] = useState(null);

    const handleEdit = () => {
        setIsEditing(!isEditing);
    }

    const handleConditionChange = (event) => {
        setSelectedCondition(event.target.value);
    }


    const handlePriceChange = (e) => {
        setPrice(e.target.value);
    };


    const updateApprovalStatus = (state) => {
        fetch(`${config.baseUrl}/v1/products/${props.id}/validate?validation=${state}`, {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
                'x-api-key': config.apiKey,
                "Content-Type": "application/json"
            },
            credentials: 'same-origin',
        })
            .then((response) => response.json())
            .catch((error) => {
                console.error(error);
            });
    }
    const updateStatus = () => {

        if (props.productCondition !== selectedCondition) {
            fetch(`${config.baseUrl}/v1/products/${props.id}/condition?condition=${selectedCondition}`, {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
                    'x-api-key': config.apiKey,
                    "Content-Type": "application/json"
                },
                credentials: 'same-origin',
            })
                .then((response) => response.json())
                .catch((error) => {
                    console.error(error);
                });
        }

        if (props.productPrice !== price) {
            fetch(`${config.baseUrl}/v1/products/${props.id}/price?price=${price}`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
                    "x-api-key": config.apiKey,
                    'Content-Type': 'application/json'
                },
                credentials: "same-origin",
            })
                .then((response) => response.json())

                .catch((error) => console.log(error));
        }

        if (props.productDesc !== descInfo) {
            fetch(`${config.baseUrl}/v1/products/${props.id}/description`, {
                method: 'PATCH',
                body: JSON.stringify(descInfo),
                headers: {
                    Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
                    'x-api-key': config.apiKey,
                    "Content-Type": "application/json"
                },
                credentials: 'same-origin',
            })
                .then((response) => response.json())
                .catch((error) => {
                    console.error(error);
                });
        }

        if (props.customerDesc !== customerDesc) {
            fetch(`${config.baseUrl}/v1/products/${props.id}/description/customer`, {
                method: 'PATCH',
                body: JSON.stringify(customerDesc),
                headers: {
                    Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
                    'x-api-key': config.apiKey,
                    "Content-Type": "application/json"
                },
                credentials: 'same-origin',
            })
                .then((response) => response.json())
                .catch((error) => {
                    console.error(error);
                });
        }
    }

    useEffect(() => {
        if (isApproval) {
            updateApprovalStatus(true);
        } else if (isReject) {
            updateApprovalStatus(false);
        }
    }, [isApproval, isReject]);


    const handleDescChange = (e) => {
        setDescInfo(e.target.value);
    };

    const handleCustomerDescChange = (e) => {
        setCustomerDesc(e.target.value);
    };

    let data = JSON.parse(localStorage.getItem("approvalProducts")) || []
    let target = data?.findIndex(obj => obj?.referenceCode === props?.productRefNo)

    useEffect(() => {

        setDescInfo(props.productDesc)
        setCustomerDesc(props.customerDesc)
        if (localStorage.getItem("approvalProducts")) {
            setProductInfo(data[target]);
        } else {
            setDescInfo(props.productDesc)
            setCustomerDesc(props.customerDesc)
        }

        const filteredItem = JSON.parse(localStorage.getItem("approvalProducts"))?.find((item) =>
            item?.itemCodes?.some((code) => code?.itemCode === props?.productItemNo)
        );
        if (filteredItem) {
            setSize(filteredItem.itemCodes.find((code) => code.itemCode === props.productItemNo).size);
        }
    }, [localStorage.getItem("approvalProducts")])

    const receiveWarning = (data) => {
        setWarning(data);
    }

    const handleImageListLengthChange = (length) => {
        setImageListLength(length);
    };

    const handleAddPhoto = (event) => {
        setLoading(true)
        const files = Array.from(event.target.files);
        if (files.length > 0) {
            files.forEach((file) => {
                const formData = new FormData();
                formData.append("file", file);

                fetch(`${config.baseUrl}/v1/products/medias?productId=${props.id}`, {
                    body: formData,
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
                        "x-api-key": config.apiKey,
                    },
                })
                    .then((data) => {
                        console.log("Fotoğraf başarıyla yüklendi:", data);
                        setPhotoKey((prevKey) => prevKey + 1);
                        setLoading(false)
                    })
                    .catch((error) => {
                        console.error("Hata:", error);
                        setError(true)
                        setLoading(false)
                    });
            });
        }
    };

    return (
        <div>
            <div className="productCard bg-white m-auto w-11/12 rounded-lg my-6 border-2" >
                <Accordion
                    className="!shadow-none !rounded-lg"
                >
                    <>
                        <AccordionSummary
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <div className="flex justify-center items-center border-b">
                                <span className="mr-1">
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M6.99967 13.6673C3.31767 13.6673 0.333008 10.6827 0.333008 7.00065C0.333008 3.31865 3.31767 0.333984 6.99967 0.333984C10.6817 0.333984 13.6663 3.31865 13.6663 7.00065C13.6663 10.6827 10.6817 13.6673 6.99967 13.6673ZM6.99967 12.334C9.94519 12.334 12.333 9.94617 12.333 7.00065C12.333 4.05513 9.94519 1.66732 6.99967 1.66732C4.05416 1.66732 1.66634 4.05513 1.66634 7.00065C1.66634 9.94617 4.05416 12.334 6.99967 12.334ZM10.333 7.00065H7.66634V3.66732H6.33301V8.33398H10.333V7.00065Z" fill="#E3262F" />
                                    </svg>
                                </span>
                                <span className="text-red-600 font-bold text-center text-sm py-2"> {t("InTheApprovalProcess")} </span>
                            </div>
                            <div className="flex place-content-between mt-4 rounded border cursor-pointer" onClick={() => { setIsExpanded(!isExpanded) }}>
                                <div className="w-1/3 relative">
                                    <div className="max-w-full h-auto p-2 mb-5">
                                        {productInfo?.mainImageUrl ?
                                            <img src={productInfo?.mainImageUrl} alt="" />
                                            :
                                            <svg
                                                width="118"
                                                height="110"
                                                viewBox="0 0 118 110"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="max-w-full h-auto m-auto"
                                            >
                                                <path
                                                    d="M36.028 34.2192H34.528V35.7192V39.816L10.2291 21.1246L33.9068 3.03378V7.76551V9.26551H35.4068H55.2849H55.3162L55.3474 9.2642C57.7213 9.16529 64.4248 9.32371 71.7445 10.7857C82.4238 13.2058 90.2727 18.9907 95.6645 25.0343L94.8137 26.0708L79.148 45.1544C74.2529 37.1395 65.407 34.5315 61.2985 34.2234L61.2425 34.2192H61.1863H36.028Z"
                                                    stroke="black"
                                                    strokeWidth="3"
                                                />
                                                <path
                                                    d="M7.45432 82.8743H8.94614L8.9543 81.3825L9.24839 27.5626L34.2186 46.7064V81.3743V82.8743H35.7186H39.816L21.1379 107.156L2.99341 82.8743H7.45432Z"
                                                    stroke="black"
                                                    strokeWidth="3"
                                                />
                                                <path
                                                    d="M81.6868 56.891H77.6697L95.9657 34.0873L113.997 56.891H109.641H108.141V58.391C108.141 65.322 106.323 74.11 103.311 80.7414C92.95 102.413 71.6803 108.037 62.4214 108.139H27.8675L47.0239 82.8751H61.8086H61.8332L61.8578 82.8743C70.0209 82.6067 75.3296 78.386 78.5792 73.2997C81.7902 68.2737 83.022 62.3782 83.1855 58.4534L83.2506 56.891H81.6868Z"
                                                    stroke="black"
                                                    strokeWidth="3"
                                                />
                                            </svg>
                                        }

                                    </div>
                                    <span className="text-xs ml-2 text-gray bg-gray-100 p-1 rounded absolute bottom-1">{props.productRefNo}</span>
                                </div>
                                <div className="flex flex-col text-sm w-2/3 bg-gray-50 p-2.5">
                                    <span className="font-bold">{productInfo ? productInfo?.brand : t("Loading")}</span>
                                    <span>
                                        {productInfo ? productInfo.title : "Loading..."}
                                    </span>
                                </div>
                            </div>
                        </AccordionSummary>
                        <div className="flex flex-col text-center text-xs text-gray-500 p-3">
                            <span>{t("AddedDate")}: {props.addedTime}</span>
                            <span>{t("AddedBy")}: {props.addedBy}</span>
                        </div>
                    </>
                    {preview && (
                        <Modal
                            className="h-full bg-white overflow-y-scroll"
                            isOpen={preview}
                        >
                            <div className="flex flex-col photoListing">
                                <div className="flex justify-between font-bold py-4">
                                    <span className="text-xl text-black"> {t("ProductPhotos")} </span>
                                    <div className="flex gap-10" >
                                        <input type="file" id="file-input" name="file-input" className={` file-input__input `} multiple onChange={handleAddPhoto} disabled={imageListLength >= 5} />
                                        <label className={`self-center ${imageListLength >= 5 ? "opacity-40" : ''} `} htmlFor="file-input">
                                            <span className="font-bold blue600 border-b-2 border-[#00537d] flex items-center">{t("AddPhoto")}</span>
                                        </label>
                                        <button className="font-bold blue600 border-b-2 border-[#00537d] flex items-center" onClick={() => setPreview(false)}>
                                            {t("Close")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="photoListing">
                                <GetPhoto onImageListLengthChange={handleImageListLengthChange} key={photoKey} preview={true} productId={props.id} />
                            </div>
                            {loading || error ? (
                                <div className="absolute w-full h-full top-0 z-50 flex items-center justify-center left-0" style={{ backgroundColor: "#000000b8" }}>
                                    <div className="border rounded-lg bg-white p-12 text-center">
                                        {loading && <>
                                            <svg aria-hidden="true" className="w-full h-16 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600 mb-3" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                            </svg>
                                            <span className="text-black text-lg tracking-wide">
                                                {t("AddingPhotos")}
                                            </span>
                                        </>}

                                        {error &&
                                            <>
                                                <svg className="w-full h-16 mb-3" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M14.6113 13.3333L8.26066 2.3333C8.14157 2.12705 7.9215 2 7.68333 2C7.44517 2 7.22509 2.12705 7.106 2.3333L0.755332 13.3333C0.636245 13.5396 0.636243 13.7937 0.755328 14C0.874412 14.2062 1.09449 14.3333 1.33267 14.3333H14.034C14.2722 14.3333 14.4923 14.2062 14.6113 14C14.7304 13.7937 14.7304 13.5396 14.6113 13.3333ZM12.8793 13H2.48735L7.68335 3.99996L12.8793 13ZM7.0167 11H8.35003V12.3333H7.0167V11ZM8.35003 6.33329H7.0167V9.66662H8.35003V6.33329Z" fill="#001018" />
                                                </svg>
                                                <div className="text-black text-lg mb-5">
                                                    {t("ErrorDuringIns")}
                                                </div>
                                                <button className="border rounded-lg p-3 border-black" onClick={() => window.location.reload()}>
                                                    {t("TryAgain")}
                                                </button>
                                            </>}
                                    </div>
                                </div>
                            ) : <></>}
                        </Modal>
                    )}
                    <AccordionDetails>
                        {isExpanded && (
                            <div className="flex flex-col gap-4 approvalCard grey400">
                                <div className="flex justify-between font-bold border-t pt-4">
                                    <span className="text-xl text-black">{t("ProductDetails")}</span>
                                    <button className={`font-bold blue600 border-b-2 border-[#00537d] flex items-center ${get("claims").data.role === "SA" ? 'hidden' : ''} `} onClick={() => { handleEdit(); isEditing && updateStatus() }}>
                                        <span> <img src="https://contents.mediadecathlon.com/s999052/k$b83df6138a3534c1e63056948ddd1e73/pencil%20%201%20%201.png" alt="ICON" /></span>
                                        {isEditing ? t("Save") : t("Edit")}
                                    </button>

                                </div>

                                <label>
                                    <span className="font-bold"> {t("Size")} </span>
                                    <span>{size ? size : t("NotFound")} </span>
                                </label>

                                {typeOfApp === 'buyBack' && (
                                    <label>
                                        <span className="font-bold"> {t("Years")} </span>
                                        <span>{years ? years : t("NotFound")} </span>
                                    </label>
                                )}

                                <label>
                                    <span className="font-bold"> {t("Price")} </span>
                                    {isEditing && !(get("claims").data.role === "SA") ? <input type="text" value={price} onChange={handlePriceChange} className={"text-right px-1 w-2/5 border rounded " + (isEditing ? 'text-black' : '')} /> :
                                        <span>{price ? price : t("NotFound")} ₺</span>}
                                </label>

                                <label>
                                    <span className={"font-bold " + (isEditing ? 'text-black' : '')}> {t("Condition")} </span>
                                    <select name="condition" id="condition" className={"border rounded " + (isEditing ? 'text-black' : '')} disabled={!isEditing && !(get("claims").data.role === "SA")} value={selectedCondition} onChange={handleConditionChange}>
                                        <option value={40}>{t("BrandNew")}</option>
                                        <option value={30}>{t("GoodCondition")}</option>
                                        <option value={20}>{t("NotBad")}</option>
                                        <option value={10}>{t("NotGood")}</option>
                                    </select>
                                </label>

                                {typeOfApp === "secondLife" && (<label>
                                    <span className="font-bold"> {t("ProductDamagedInWarehouse")} </span>
                                    <span>{props.productDamagedInWh ? t("Yes") : t("No")}</span>
                                </label>)}

                                {typeOfApp === 'buyBack' && (
                                    <div className="flex flex-col">
                                        <div className="flex justify-between font-bold py-4">
                                            <span className="text-xl text-black"> {t("ProductPhotos")} </span>
                                            <button className={`font-bold blue600 border-b-2 border-[#00537d] flex items-center `} onClick={() => { setPreview(true) }} >
                                                {t("Display")}
                                            </button>
                                        </div>
                                        {!preview && <GetPhoto onData={receiveWarning} productId={props.id} />}
                                    </div>
                                )}

                                <label className="flex-col text-black font-bold">
                                    {t("ProductDescription")}:
                                    <textarea name="desc" readOnly={!isEditing} id="" value={descInfo} onChange={handleDescChange} cols="30" rows="2" className={"border rounded p-3 mt-1 " + (!isEditing ? 'text-gray-400' : 'border-current')} />
                                </label>
                                <label className="flex-col text-black font-bold">
                                    {t("CustomerDescription")}:
                                    <textarea name="desc" readOnly={!isEditing} id="" value={customerDesc} onChange={handleCustomerDescChange} cols="30" rows="2" className={"border rounded p-3 mt-1 " + (!isEditing ? 'text-gray-400' : 'border-current')} />
                                </label>
                                {get("claims").data.role === "SA" || get("claims").data.role === "CL" ?
                                    (<div className="flex flex-col gap-4 mb-4"></div>) : (
                                        <div className="flex flex-col gap-4 mb-4">
                                            <button className="w-full blue600 border p-3 bgBlue100 blue100 font-bold rounded" onClick={() => { setIsExpanded(false); setIsReject(true); window.scrollTo(0, 0); }}>{t("Reject")}</button>
                                            <button className="w-full bgBlue500 border p-3 text-white font-bold rounded" onClick={() => { setIsExpanded(false); setIsApproval(true); window.scrollTo(0, 0); }}>{t("ApprovalButton")}</button>
                                        </div>
                                    )}
                            </div>
                        )}
                    </AccordionDetails>
                </Accordion>

                <Dialog
                    open={isApproval}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <div className={`flex items-center justify-center w-full`}>
                            <div className="flex flex-col bg-white w-4/5 h-2/5 justify-around items-center rounded gap-5" >
                                <div className="p-7 rounded-full" style={{ background: "#E7F3F9" }}>
                                    <svg width="41" height="29" viewBox="0 0 41 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.902 21.8765L37.5672 0.208984L40.9023 3.54173L15.902 28.542L0.902344 13.5423L4.23509 10.2096L15.902 21.8765Z" fill="#007DBC" />
                                    </svg>
                                </div>
                                <span className="text-xl font-bold blue600 text-center">{t("ProductApproved")}</span>
                                <button className="approvedButton w-3/4" onClick={() => { setIsApproval(false); window.location.reload(); }}>{t("Okay")}</button>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>

                <Dialog
                    open={isReject}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent className=" !w-full">
                        <div className={`flex items-center justify-center w-full`}>
                            <div className="flex flex-col bg-white w-full h-2/5 justify-around items-center rounded gap-5" >
                                <div className="p-7 rounded-full" style={{ background: "#E7F3F9" }}>
                                    <svg width="41" height="29" viewBox="0 0 41 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.902 21.8765L37.5672 0.208984L40.9023 3.54173L15.902 28.542L0.902344 13.5423L4.23509 10.2096L15.902 21.8765Z" fill="#007DBC" />
                                    </svg>
                                </div>
                                <span className="text-xl font-bold blue600 text-center">{t("ProductRejected")}</span>
                                <button className="approvedButton  w-3/4" onClick={() => { setIsReject(false); window.location.reload(); }}>{t("Okay")}</button>
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        </div >
    );
};


