import React, { useEffect, useState } from "react";
import { get } from "../../../services/cache";
import config from "../../../services/config";
import { useTranslation } from "react-i18next";
import Dialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import dcareLogo from '../../../assets/dcareLogo.svg';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

export const WriteOffCard = (props) => {

    let typeOfApp = localStorage.getItem("typeOfApp");

    const [productInfo, setProductInfo] = useState();
    const userAccessToken = get("fedidLoginAccessToken");
    const [content, setContent] = useState('');
    const [contentDisabled, setContentDisabled] = useState(true);
    const [description, setDescription] = useState('');
    const [writeOffReasons, setWriteOffReasons] = useState();

    const { t } = useTranslation();

    const [open, setOpen] = useState(false);

    let data = JSON.parse(localStorage.getItem("stockControlProducts")) || []
    let target = data?.findIndex(obj => obj?.referenceCode === props?.productRefNo)

    const handleContentChange = (event) => {
        setContent(event.target.value);
        setContentDisabled(false)
    };

    const handleDescChange = (event) => {
        setDescription(event.target.value);
    };


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const writeOffProduct = {
        "productId": props.id,
        "removeExplanation": content,
        "explanation": description,
        "explanation2": ""
    };

    const handleDelete = () => {
        fetch(`${config.baseUrl}/v1/products/remove`, {
            method: "POST",
            body: JSON.stringify(writeOffProduct),
            headers: {
                Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
                "x-api-key": config.apiKey,
                'Content-Type': 'application/json'
            },
            credentials: "same-origin",
        })
            .then(res => { props.refreshProducts(true); setOpen(false) })
            .catch((error) => console.log(error));
    }

    useEffect(() => {


        if (localStorage.getItem("stockControlProducts")) {
            setProductInfo(data[target]);
        }


    }, [localStorage.getItem("stockControlProducts")])

    return (
        <div className={` ${productInfo ? '' : ""} `}>
            <div className="productCard bg-white m-auto w-11/12 rounded-lg my-6 border-2 px-4" >
                <div className="flex place-content-between mt-4 rounded border cursor-pointer" >
                    <div className="w-1/3 relative">
                        <div className="max-w-full h-auto p-2 mb-5">
                            {productInfo?.mainImageUrl ?
                                <img src={productInfo?.mainImageUrl} alt="" />
                                :
                                <svg
                                    width="118"
                                    height="110"
                                    viewBox="0 0 118 110"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="max-w-full h-auto m-auto"
                                >
                                    <path
                                        d="M36.028 34.2192H34.528V35.7192V39.816L10.2291 21.1246L33.9068 3.03378V7.76551V9.26551H35.4068H55.2849H55.3162L55.3474 9.2642C57.7213 9.16529 64.4248 9.32371 71.7445 10.7857C82.4238 13.2058 90.2727 18.9907 95.6645 25.0343L94.8137 26.0708L79.148 45.1544C74.2529 37.1395 65.407 34.5315 61.2985 34.2234L61.2425 34.2192H61.1863H36.028Z"
                                        stroke="black"
                                        strokeWidth="3"
                                    />
                                    <path
                                        d="M7.45432 82.8743H8.94614L8.9543 81.3825L9.24839 27.5626L34.2186 46.7064V81.3743V82.8743H35.7186H39.816L21.1379 107.156L2.99341 82.8743H7.45432Z"
                                        stroke="black"
                                        strokeWidth="3"
                                    />
                                    <path
                                        d="M81.6868 56.891H77.6697L95.9657 34.0873L113.997 56.891H109.641H108.141V58.391C108.141 65.322 106.323 74.11 103.311 80.7414C92.95 102.413 71.6803 108.037 62.4214 108.139H27.8675L47.0239 82.8751H61.8086H61.8332L61.8578 82.8743C70.0209 82.6067 75.3296 78.386 78.5792 73.2997C81.7902 68.2737 83.022 62.3782 83.1855 58.4534L83.2506 56.891H81.6868Z"
                                        stroke="black"
                                        strokeWidth="3"
                                    />
                                </svg>
                            }
                        </div>
                        <span className="text-xs ml-2 text-gray bg-gray-100 p-1 rounded absolute bottom-1">{props.productRefNo}</span>
                    </div>
                    <div className="flex flex-col text-sm w-2/3 bg-gray-50 p-2.5 relative">
                        <div className="flex justify-between items-center py-1.5">
                            <span className="font-bold">{productInfo?.brand ? productInfo?.brand : t("Loading")} </span>
                            <span className="font-bold p-1.5 rounded absolute right-0" style={{ backgroundColor: '#FFEA28' }}> {props.productPrice} ₺</span>
                        </div>
                        <span>
                            {productInfo ? productInfo.title : t("Loading")}
                        </span>
                    </div>
                </div>
                <div className="flex flex-col text-center text-xs text-gray-500 py-3">
                    <span>{t("AddedDate")} : {props.addedTime}</span>
                    <span>{t("AddedBy")} : {props.addedBy}</span>
                </div>
                <div className="flex gap-4 text-sm py-3 font-bold">
                    <Button
                        variant="contained"
                        className="w-full !text-white !font-bold !bg-[#00537D] disabled:opacity-60 !capitalize"
                        onClick={handleClickOpen}
                    >
                        {t("WriteOff")}
                    </Button>
                </div>
                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Slide}
                >
                    <AppBar sx={{ position: 'relative' }} className="  w-full p-3 flex content-center justify-between items-center fixed top-0 z-20 " style={{ backgroundColor: (typeOfApp === 'secondLife' ? "#00537D" : '#012B49') }} >
                        <button onClick={handleClose} className="outline-none w-1/6 z-10 absolute left-3 top-4" >
                            <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.18766 8.50096L10.7877 15.101L8.90232 16.9863L0.416992 8.50096L8.90232 0.015625L10.7877 1.90096L4.18766 8.50096Z" fill="white" />
                            </svg></button>
                        <p className="uppercase text-white font-bold text-md text-center w-full"> {t("StockManagement")}</p>
                    </AppBar>
                    <div className="productCard bg-white m-auto w-11/12 rounded-lg my-6 border-2 px-4" >
                        <div className="flex place-content-between mt-4 rounded border cursor-pointer" >
                            <div className="w-1/3 relative">
                                <div className="max-w-full h-auto p-2 mb-5">
                                    {productInfo?.mainImageUrl ?
                                        <img src={productInfo?.mainImageUrl} alt="" /> : <img src={dcareLogo} alt="" />
                                    }
                                </div>
                                <span className="text-xs ml-2 text-gray bg-gray-100 p-1 rounded absolute bottom-1">{props.productRefNo}</span>
                            </div>
                            <div className="flex flex-col text-sm w-2/3 bg-gray-50 p-2.5 relative">
                                <div className="flex justify-between items-center py-1.5">
                                    <span className="font-bold">{productInfo?.brand ? productInfo?.brand : t("Loading")} </span>
                                    <span className="font-bold p-1.5 rounded absolute right-0" style={{ backgroundColor: '#FFEA28' }}> {props.productPrice} ₺</span>
                                </div>
                                <span>
                                    {productInfo ? productInfo.title : t("Loading")}
                                </span>
                            </div>
                        </div>
                        <div className="flex flex-col text-center text-xs text-gray-500 py-3">
                            <span>{t("AddedDate")} : {props.addedTime}</span>
                            <span>{t("AddedBy")} : {props.addedBy}</span>
                        </div>
                        <Divider className="!mb-5 !mt-2" />

                        <div className="">
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth className="gap-5">
                                    <>
                                        <InputLabel id="write-off-reasons-label">{t("WriteOffReasons")}</InputLabel>
                                        <Select
                                            labelId="write-off-reasons-label"
                                            id="write-off-reasons-label"
                                            value={content}
                                            label={t("WriteOffReasons")}
                                            onOpen={() => setWriteOffReasons(get("WriteOffReasons")?.data)}
                                            onChange={handleContentChange}
                                        >
                                            {writeOffReasons?.length &&
                                                writeOffReasons.map((reasons) =>
                                                (<MenuItem className={`${(typeOfApp === 'buyBack') && (reasons.value === 0 || reasons.value === 1) ? '!hidden' : ''}`}
                                                    value={reasons.value} key={reasons.value} >
                                                    {t(`${reasons.name}`)}
                                                </MenuItem>))}
                                        </Select>
                                    </>
                                    <TextField
                                        label={t("EnterDescription")}
                                        multiline
                                        rows={4}
                                        onChange={handleDescChange}
                                    />
                                </FormControl>
                            </Box>
                        </div>
                        <div className="flex gap-4 text-sm py-3 font-bold">
                            <Button
                                variant="contained"
                                className="w-full !text-white !font-bold !bg-[#00537D] disabled:opacity-60 !capitalize"
                                disabled={!description || contentDisabled}
                                onClick={handleDelete}
                            >
                                {t("WriteOff")}
                            </Button>
                        </div>

                    </div>
                </Dialog>
            </div>
        </div>
    );
};

WriteOffCard.propTypes = {
    productRefNo: PropTypes.string,
    id: PropTypes.number,
    productPrice: PropTypes.number,
    addedTime: PropTypes.string,
    addedBy: PropTypes.string,
    refreshProducts: PropTypes.func
};
export default WriteOffCard;
