import React, { useEffect, useState } from "react";
import { get, set } from "../../../services/cache";
import config from "../../../services/config";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../../Loading";
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import { t } from "i18next";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

export const ProductPrice = (props) => {


    const location = useLocation();
    const userAccessToken = get("fedidLoginAccessToken");

    const [priceInfoData, setPriceInfoData] = useState([]);
    const [role, setRole] = useState();
    let typeOfApp = localStorage.getItem("typeOfApp");


    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [checkPriceData, setCheckPriceData] = useState(false);
    const [sendProduct, setSendProduct] = useState(false);
    const [selected, setSelected] = useState('');
    const [finalPrice, setFinalPrice] = useState();
    const [manuelPrice, setManuelPrice] = useState('');
    const [priceData, setPriceData] = useState([]);
    const options = ['%20 Discount', '%15 Discount', '%10 Discount'];

    const redirect = useNavigate();

    const [discount, setDiscount] = React.useState('15');
    const [buyBackDiscount, setBuyBackDiscount] = useState(null);
    // const [buyBackBuyingPrice, setBuyBackBuyingPrice] = useState(null);
    const [buyBackSalePrice, setBuyBackSalePrice] = useState(null);
    const [open, setOpen] = React.useState(false);

    const handleChanges = (event) => {
        setDiscount(event.target.value);
        setFinalPrice(event.target.value);
        setManuelPrice('');
    };


    const postDiscount = () => {

        fetch(`${config.baseUrl}/v1/products/buyback/price`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
                "x-api-key": config.apiKey,
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                rim: get("AddProductDetails").data.sportBranchId === "168" ? get("ProductFrame").data : 0,
                age: get("ProductAge").data,
                state: get("conditionInfo").data,
                groupType: parseInt(get("AddProductDetails").data.sportBranchId)
            }),
            credentials: "same-origin",

        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then((res) => {
                setBuyBackDiscount(res.buying)
                setBuyBackSalePrice(res.selling)
            })
            .catch((error) => {
                console.error('Bir hata oluştu:', error);
            });
    };

    const buyBackProduct = {
        productType: 1,
        modelCode: get("ProductModelCode")?.data,
        itemCode: get("ProductItemCode")?.data,
        description: "",
        descriptionToCustomer: "",
        size: get("ProductSize")?.data,
        serialNumber: "",
        buyingPrice: priceInfoData * buyBackDiscount / 100,
        age: get("ProductAge")?.data,
        condition: get("addCondition")?.data,
        finalPrice: priceInfoData * buyBackSalePrice / 100,
    }

    const handleAddBuyBack = () => {
        fetch(`${config.baseUrl}/v1/products`, {
            method: "POST",
            body: JSON.stringify(buyBackProduct),
            headers: {
                Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
                "x-api-key": config.apiKey,
                "Content-Type": "application/json",
            },
            credentials: "same-origin",
        })
            .then((response) => response.json())
            .then((res) => {
                set({ key: "AddProductData", data: res?.data });
                saveButton();
                redirect("/sh-add-product/contract-buyback")
            })
            .catch((error) => console.log(error));
    }

    useEffect(() => {
        if (typeOfApp === "secondLife") {
            fetch(`${config.baseUrl}/v1/products/details/${get("secondLifeProduct").data?.modelCode}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
                    "x-api-key": config.apiKey,
                },
                credentials: "same-origin",
            })
                .then((response) => response.json())
                .then((res) => {
                    setPriceData(res.data)
                })
                .catch((error) => console.log(error));

            fetch(`${config.baseUrl}/v1/products/system_price/${get("secondLifeProduct")?.data?.itemCode}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
                    "x-api-key": config.apiKey,
                },
                credentials: "same-origin",
            })
                .then((response) => response.json())
                .then((res) => {
                    setPriceInfoData(res.data);
                })
        } else {
            setPriceData(get("AddProductDetails").data)
            fetch(`${config.baseUrl}/v1/products/system_price/${get("buybackProduct")?.data?.itemCode}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
                    "x-api-key": config.apiKey,
                },
                credentials: "same-origin",
            })
                .then((response) => response.json())
                .then((res) => {
                    setPriceInfoData(res.data);
                    setCheckPriceData(true);
                })
            postDiscount();
        }

        if (!userAccessToken?.data?.access_token) {
            redirect("/");
        }
    }, [])

    useEffect(() => {
        const cachedData = get("buybackProduct")?.data;
        if (cachedData) {
            const updatedData = { ...cachedData, buyingPrice: priceInfoData / 100 * (100 - buyBackDiscount) };
            set({ key: "buybackProduct", data: updatedData });
        }
    }, [buyBackDiscount, priceInfoData])

    useEffect(() => {
        const cachedData = get("secondLifeProduct")?.data;
        if (cachedData) {
            const updatedData = { ...cachedData, finalPrice: manuelPrice ? Math.ceil(manuelPrice) : Math.ceil(priceInfoData * (100 - discount) / 100) };
            set({ key: "secondLifeProduct", data: updatedData });
        }
    }, [discount, manuelPrice, priceInfoData])

    useEffect(() => {
        if (!userAccessToken?.data?.access_token) {
            redirect("/");
            window.location.reload();
        }
        let backButton;
        backButton = document.getElementById("backButton");
        backButton.addEventListener('click', goBack);
    })


    const handleChange = (event) => {
        setManuelPrice(parseInt(event?.target?.value));
        setDiscount('');
    };

    const saveButton = () => {
        redirect("/sh-add-product")
        if (finalPrice > 0 && typeOfApp === "secondLife") {
            fetch(`${config.baseUrl}/v1/products/${get("AddProductData").data?.id}/discount?discount=${finalPrice}`, {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
                    "x-api-key": config.apiKey,
                    'Content-Type': 'application/json'
                },
                credentials: "same-origin",
            })
                .then((response) => response.json())
                .then((res) => localStorage.setItem("price", res.data))
                .catch((error) => console.log(error));
        }
        if (manuelPrice > 0 || buyBackSalePrice) {

            fetch(`${config.baseUrl}/v1/contracts`, {
                method: "POST",
                body: JSON.stringify({
                    productId: get("AddProductData")?.data?.id,
                    store: get("claims")?.data.storeCode,
                }),
                headers: {
                    Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
                    "x-api-key": config.apiKey,
                    "Content-Type": "application/json",
                },
                credentials: "same-origin",
            })
                .then((response) => response.json())
                .then((res) => {
                    set({ key: "BuyBackContract", data: res?.data });
                })
                .catch((error) => console.log(error));
        }

        if (typeOfApp === "secondLife") { redirect('/sh-add-product'); }
    }

    useEffect(() => {
        setRole(get("claims")?.data?.role)
        if (role === "Admin") {
            setSelected(options[2]);
            setDropdownOpen(false);
        }

    }, [role])

    function goBack() {
        props.changeShowCard(true);
    }


    return (
        <div className="w-full h-full flex justify-center flex-col mt-[3rem] " style={{ background: "#fff" }} >

            {priceInfoData > 0 || (typeOfApp === "buyBack" && checkPriceData) ? <></> : <Loading />}
            <div className="productCard bg-white m-auto w-11/12 rounded-lg my-6 border-2 px-4" >
                <div className="flex place-content-between my-4 rounded border cursor-pointer" >
                    <div className="w-1/3 relative">
                        <div className="max-w-full h-auto p-2 mb-5">
                            {get("AddProductDetails")?.data ?
                                <img src={get("AddProductDetails")?.data?.mainImageUrl} alt="" />
                                :
                                <svg
                                    width="118"
                                    height="110"
                                    viewBox="0 0 118 110"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="max-w-full h-auto m-auto"
                                >
                                    <path
                                        d="M36.028 34.2192H34.528V35.7192V39.816L10.2291 21.1246L33.9068 3.03378V7.76551V9.26551H35.4068H55.2849H55.3162L55.3474 9.2642C57.7213 9.16529 64.4248 9.32371 71.7445 10.7857C82.4238 13.2058 90.2727 18.9907 95.6645 25.0343L94.8137 26.0708L79.148 45.1544C74.2529 37.1395 65.407 34.5315 61.2985 34.2234L61.2425 34.2192H61.1863H36.028Z"
                                        stroke="black"
                                        strokeWidth="3"
                                    />
                                    <path
                                        d="M7.45432 82.8743H8.94614L8.9543 81.3825L9.24839 27.5626L34.2186 46.7064V81.3743V82.8743H35.7186H39.816L21.1379 107.156L2.99341 82.8743H7.45432Z"
                                        stroke="black"
                                        strokeWidth="3"
                                    />
                                    <path
                                        d="M81.6868 56.891H77.6697L95.9657 34.0873L113.997 56.891H109.641H108.141V58.391C108.141 65.322 106.323 74.11 103.311 80.7414C92.95 102.413 71.6803 108.037 62.4214 108.139H27.8675L47.0239 82.8751H61.8086H61.8332L61.8578 82.8743C70.0209 82.6067 75.3296 78.386 78.5792 73.2997C81.7902 68.2737 83.022 62.3782 83.1855 58.4534L83.2506 56.891H81.6868Z"
                                        stroke="black"
                                        strokeWidth="3"
                                    />
                                </svg>
                            }
                        </div>
                        <span className="text-xs ml-2 text-gray bg-gray-100 p-1 rounded absolute bottom-1">{priceData.referenceCode}</span>
                    </div>
                    <div className="flex flex-col text-sm w-2/3 bg-gray-50 p-2.5 relative">
                        <div className="flex justify-between items-center py-1.5">
                            <span className="font-bold">{priceData.brand}</span>
                        </div>
                        <span>{priceData.title}</span>
                    </div>
                </div>
                <div className="flex flex-col gap-3 mb-4">
                    {typeOfApp === "secondLife" && <span className="flex justify-between w-4/5 self-center font-bold text-sm items-center"> {t("ActualPrice")} <span className="priceTag p-1.5 rounded " >{priceInfoData} ₺</span></span>}
                    <span className="flex justify-between w-4/5 self-center font-bold text-sm items-center"> {typeOfApp === "secondLife" ? t("RecommendedPrice") : t("RecommendedBuyBackPrice")} <span className="priceTag p-1.5 rounded" >
                        {discount && typeOfApp === "secondLife" ? Math.ceil(priceInfoData * (100 - discount) / 100) : ''}
                        {manuelPrice ? Math.ceil(manuelPrice) : ''}
                        {!manuelPrice && !discount && role !== 'SA' && typeOfApp === "secondLife" ? Math.ceil(priceInfoData * 0.85) : ''}
                        {typeOfApp === "buyBack" && buyBackDiscount ? Math.ceil(priceInfoData * buyBackDiscount / 100) : ''}
                        ₺</span> </span>
                </div>
            </div>

            {typeOfApp === "secondLife" ? (<div className="self-center items-center flex flex-col gap-8">
                <span className="text-2xl blue600 font-bold">{t("EnterPriceOrDiscount")}.</span>
                <input type="text" placeholder={`${t("Price")}`} className="w-11/12 border h-11 p-3 rounded priceInput" onChange={handleChange} value={manuelPrice ? manuelPrice : ''} disabled={role === "SA"} />
                <span className="text-2xl blue600 font-bold">{t("Or")}</span>
                <FormControl sx={{ m: 1, minWidth: 120 }} className="w-11/12 border h-11 p-3 rounded priceInput">
                    <NativeSelect
                        onChange={handleChanges}
                        className="h-full p-3"
                        disabled={role === "SA"}
                    >
                        <option value="" selected={manuelPrice ? true : false}>{t("Discount")}</option>
                        <option value={5}>%5</option>
                        <option value={10}>%10</option>
                        <option value={15} selected={role === "SA"}>%15</option>
                        <option value={20}>%20</option>
                        <option value={25}>%25</option>
                    </NativeSelect>
                </FormControl>
            </div>) : (
                <div className="productCard bg-white m-auto w-11/12 my-6 flex flex-col">
                    <span className="mb-2">{t("PurchasePrice")}</span>
                    <input type="text" placeholder={`${t("Price")}`} className="w-full border h-11 p-3 rounded priceInput" onChange={handleChange} value={manuelPrice ? manuelPrice : ''} disabled={role === "SA" || priceData !== null} />
                </div>
            )}
            {/* saveButton(); */}
            <div className="addProductStickyButtonsBg">
                <button className="w-11/12 addProductStickyButtons z-10" onClick={() => { typeOfApp === "secondLife" ? saveButton() : setSendProduct(true) }}>{typeOfApp === "secondLife" ? t("Save") : t("Next")}</button>
            </div>

            <Dialog
                open={sendProduct}
                onClose={() => { setSendProduct(!sendProduct) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent className="!px-0">
                    <div className={`flex items-center justify-center w-full`}>
                        <div className="flex flex-col bg-white w-full h-2/5 justify-around items-center rounded gap-5" >
                            <div className="p-7 rounded-full" style={{ background: "#E7F3F9" }}>
                                <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_2593_45907)">
                                        <path d="M18.3991 3.8291L34.8975 6.18744L37.2541 22.6874L21.9341 38.0074C21.6216 38.3199 21.1977 38.4954 20.7558 38.4954C20.3138 38.4954 19.89 38.3199 19.5775 38.0074L3.07745 21.5074C2.765 21.1949 2.58948 20.771 2.58948 20.3291C2.58948 19.8872 2.765 19.4633 3.07745 19.1508L18.3991 3.8291ZM19.5775 7.36577L6.61245 20.3291L20.7558 34.4708L33.7191 21.5074L31.9525 9.13244L19.5775 7.36577ZM23.1108 17.9724C22.4855 17.347 22.1344 16.4987 22.1345 15.6143C22.1346 15.1764 22.2209 14.7428 22.3886 14.3383C22.5562 13.9338 22.8019 13.5662 23.1116 13.2566C23.4213 12.947 23.789 12.7015 24.1936 12.5339C24.5982 12.3664 25.0318 12.2803 25.4697 12.2803C26.3541 12.2805 27.2022 12.632 27.8275 13.2574C28.4527 13.8829 28.8039 14.7311 28.8037 15.6155C28.8036 16.4999 28.4521 17.348 27.8266 17.9733C27.2012 18.5985 26.3529 18.9497 25.4685 18.9495C24.5841 18.9494 23.736 18.5979 23.1108 17.9724Z" fill="#007DBC" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_2593_45907">
                                            <rect width="40" height="40" fill="white" transform="translate(0.232422 0.329102)" />
                                        </clipPath>
                                    </defs>
                                </svg>

                            </div>
                            <span className="text-xl font-bold blue600 text-center w-4/5">{t("NewBuyBackProductOffer")}</span>
                            <div className="flex w-full justify-center gap-5">
                                <button className="approvedButton" onClick={() => { handleAddBuyBack() }}>{t("Yes")}</button>
                                <button className="approvedButton" onClick={() => { setSendProduct(false) }} style={{ backgroundColor: "#012B49" }}>{t("No")}</button>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>

    );

}


export default ProductPrice;