import React, { useEffect, useState } from "react";
import { get, set } from "../../../services/cache";
import config from "../../../services/config";
import { useLocation, useNavigate } from "react-router-dom";
import { t } from "i18next";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
export const BuyBackAddProductInformation = (props) => {


    const location = useLocation();
    const userAccessToken = get("fedidLoginAccessToken");
    const [sendProduct, setSendProduct] = useState();
    const redirect = useNavigate();

    const [productData, setProductData] = React.useState(get("AddProductDetails")?.data.itemCodes);
    const [age, setAge] = React.useState(get("ProductAge") ? get("ProductAge").data : '');
    const [frame, setFrame] = React.useState(get("ProductFrame") ? get("ProductFrame").data : '');
    const [brand, setBrand] = React.useState(get("AddProductDetails").data.brand);
    const [size, setSize] = React.useState(get("ProductSize")?.data || '');

    const handleAgeChange = (event) => {
        setAge(event.target.value);
    };

    const handleFrameChange = (event) => {
        setFrame(event.target.value);
    };

    const handleSizeChange = (event) => {
        setSize(event.target.value);
    };

    const defaultSize = productData.find(item => item.itemCode === get("ProductItemCode").data)?.size || 'UNIQUE';

    React.useEffect(() => {
        setSize(defaultSize);
    }, [defaultSize]);

    // let checkSize;

    useEffect(() => {
        const cachedData = get("buybackProduct").data;

        if (age > 0) {
            set({ key: "ProductAge", data: age });
        }

        if (cachedData) {
            const updatedData = { ...cachedData, age: age };
            set({ key: "buybackProduct", data: updatedData });
        } else {
            console.log(cachedData)
        }

        //  checkSize = get("AddProductDetails")?.data?.filter(el =>
        //     get("AddProductDetails")?.some(el2 => el2?.itemCode === el?.itemCode)
        // );

    }, [age])

    useEffect(() => {
        if (frame > 0) {
            set({ key: "ProductFrame", data: frame });
        }
    }, [frame])

    useEffect(() => {
        if (size) {
            set({ key: "ProductSize", data: size });
        }
    }, [size])

    return (
        <div className="flex flex-col items-center">
            <div className="w-full flex flex-col items-center justify-center gap-8 productCard mt-[4.5rem]" style={{ background: "#fff" }} >
                <FormControl className="w-11/12">
                    <InputLabel id="age">{t("Age")}</InputLabel>
                    <Select
                        labelId="age"
                        id="age"
                        value={age}
                        label="Age"
                        onChange={(handleAgeChange)}
                    >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                    </Select>
                </FormControl>
                {get("AddProductDetails").data.sportBranchId === "168" &&
                    <FormControl className="w-11/12">
                        <InputLabel id="frame">{t("Frame")}</InputLabel>
                        <Select
                            labelId="frame"
                            id="frame"
                            value={frame}
                            label="Frame"
                            onChange={(handleFrameChange)}
                        >
                            <MenuItem value={14}>14</MenuItem>
                            <MenuItem value={16}>{t("FrameSize")}</MenuItem>
                        </Select>
                    </FormControl>
                }

                <FormControl className="w-11/12">
                    <InputLabel id="brand">{t("Brand")}</InputLabel>
                    <Select
                        labelId="brand"
                        id="brand"
                        value={brand}
                        label="Frame"
                        onChange={(handleFrameChange)}
                        disabled
                    >
                        <MenuItem value={brand}>{brand}</MenuItem>
                    </Select>
                </FormControl>

                <FormControl className="w-11/12">
                    <InputLabel id="size">{t("Size")}</InputLabel>
                    <Select
                        labelId="size"
                        id="size"
                        value={size}
                        label="Size"
                        onChange={handleSizeChange}
                        disabled
                    >
                        <MenuItem value={size}>
                            {size}
                        </MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div className="addProductStickyButtonsBg">
                <button className="w-11/12 addProductStickyButtons z-10" onClick={() => { redirect("/sh-add-product") }}>{t("Save")}</button>
            </div>
        </div>
    );
}


export default BuyBackAddProductInformation;