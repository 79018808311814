import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { get, set } from "../../services/cache";
import config from "../../services/config";
import { useNavigate } from "react-router-dom";
import { dateAdd } from "../../services/utils";

export const FedidLogin = () => {
  const redirect = useNavigate();

  let code = new URL(window.location.href).searchParams.get("code");

  const code_verifier = get(config.FedidCodeVerifier.cacheKey);

  useEffect(() => {
    const urlDecoded = {
      grant_type: "authorization_code",
      client_id: process.env.REACT_APP_CLIENTID,
      code,
      redirect_uri: process.env.REACT_APP_REDIRECT_URI,
      code_verifier: code_verifier?.data,
    };

    var urlEncoded = [];
    for (var property in urlDecoded) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(urlDecoded[property]);
      urlEncoded.push(encodedKey + "=" + encodedValue);
    }

    let body = urlEncoded.join("&");

    fetch(process.env.REACT_APP_FEDID_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body,
    })
      .then((response) => response.json())
      .then((res) => {
        set({
          key: config.FedidLogin.cacheKey,
          data: res,
          expires: dateAdd(new Date(), "second", 7199).toISOString(),
        });
        redirect("/select-app");
      });
  }, []);

  return <Box></Box>;
};
