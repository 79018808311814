
import React, { useEffect, useState } from "react";
import { SHApprovalCard } from "./SHApprovalCard";
import { get } from "../../services/cache";
import config from "../../services/config";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Loading from "../Loading";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

export const SHApproval = () => {

  const userAccessToken = get("fedidLoginAccessToken");
  const [approvalData, setApprovalData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [uniqRes, setUniqRes] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const [fetchFlag, setFetchFlag] = useState(false);
  const [responseArray, setResponseArray] = useState([]);

  const redirect = new useNavigate();
  let typeOfApp = localStorage.getItem("typeOfApp");
  // let filterData = approvalData.filter(item => item.status === (typeOfApp === 'secondLife' ? )).sort((a, b) => (a.id > b.id ? -1 : 1))
  let filterData = approvalData.filter(item => item.status === 0 && item.productType === (typeOfApp === 'secondLife' ? 0 : 1)).sort((a, b) => (a.id > b.id ? -1 : 1))


  useEffect(() => {
    fetch(`${config.baseUrl}/v1/products/all/stores`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
        "x-api-key": config.apiKey,
      },
      credentials: "same-origin",
    })
      .then((response) => response.json())
      .then(res => {
        setApprovalData(res.data)
      })
      .catch((error) => console.log(error));

    if (!userAccessToken?.data?.access_token) {
      redirect("/");
    }


  }, [])


  useEffect(() => {
    const uniqueData = filterData.reduce((accumulator, item) => {
      const isExisting = accumulator.find(obj => obj.modelCode === item.modelCode);
      if (!isExisting) {
        accumulator.push(item);
      }
      return accumulator;
    }, []);
    setUniqRes(uniqueData);

  })

  useEffect(() => {
    let search = filterData.filter((item) => item.modelCode === selectedOption);
    setSearchData(search);
  }, [searchData])

  const handleOptionChange = (event, value) => {
    setSelectedOption(value);
  };


  ///////////////////////////////////////////////////////

  useEffect(() => {

    if (uniqRes.length > 0 && !fetchFlag) {
      setFetchFlag(true);
      Promise.all(uniqRes.map((item, index) =>
        fetch(`${config.baseUrl}/v1/products/details/${item.modelCode}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
            "x-api-key": config.apiKey,
          },
        })
          .then((response) => response.json())
          .then((res) => {
            return res.data;
          })
          .catch((error) => console.log(error))
      )).then((responses) => {
        setResponseArray(responses)
      })
        .catch((error) => console.log(error));
    }

  }, [uniqRes, fetchFlag]);

  useEffect(() => {
    if (responseArray.length > 0) {
      if (
        JSON.stringify(responseArray) !== localStorage.getItem("approvalProducts")
      ) {
        localStorage.setItem("approvalProducts", JSON.stringify(responseArray));
      }

    }

  }, [responseArray, fetchFlag]);


  ///////////////////////////////////////////////////////

  return (
    <div className="w-full h-full mt-[8rem]" style={{ background: "#fff" }} >
      {approvalData.length === 0 ? <Loading /> : ''}
      <div
        className="productCard bg-white m-auto w-11/12 rounded-lg my-6 fixed z-10 left-0 right-0 py-4 top-4"
      >
        <Autocomplete
          disablePortal
          id="combo-box"
          options={uniqRes.map((item) => item.modelCode)}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label={`${t("EnterReferenceCode")}`} />}
          onChange={handleOptionChange}
          onInputChange={(event, newInputValue) => { setSelectedOption(newInputValue); }}
          value={selectedOption}
          className="m-auto"
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          style={{ width: "100%" }}
        />
      </div>

      {filterData.length > 0 ? filterData.map((product) => (
        <div
          key={product.id}
          style={{
            display: searchData.find((item) => item.id === product.id && typeof item.id === "number") ||
              searchData.find((item) => item.id === product.addedBy && typeof item.addedBy === "string") || searchData.length === 0 ? "block" : "none"
          }} >

          <SHApprovalCard
            key={product.name}
            productName={product.name}
            productBrand={product.brand}
            productColor={product.color}
            productRefNo={product.modelCode}
            productItemNo={product.itemCode}
            productPrice={product.finalPrice}
            productCategory={product.category}
            productSize={product.size}
            productGender={product.gender}
            productDesc={product.description}
            customerDesc={product.descriptionToCustomer}
            addedBy={product.addedBy}
            addedTime={product.addedTime}
            productCondition={product.condition}
            productStatus={product.status}
            productDamagedInWh={product.damagedInWh}
            productAge={product.age}
            id={product.id}
            uniqRefCode={uniqRes.map((item) => item.modelCode)}
          />

        </div>
      )) : <div className="z=[-20] text-center font-bold">Not Found...</div>}
    </div>
  );

}


export default SHApproval;