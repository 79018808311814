import React, { useEffect, useState } from "react";
import { SHPrintLabelCard } from "./SHPrintLabelCard";
import { get } from "../../services/cache";
import config from "../../services/config";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

export const SHPrintLabel = () => {

  const userAccessToken = get("fedidLoginAccessToken");
  const [printLabelData, setPrintLabelData] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [openCheckBox, setOpenCheckBox] = useState();

  const [uniqRes, setUniqRes] = useState([]);
  const [fetchFlag, setFetchFlag] = useState(false);
  const [responseArray, setResponseArray] = useState([]);

  const redirect = useNavigate();

  let typeOfApp = localStorage.getItem("typeOfApp");

  let filterData = printLabelData?.filter(item => item.status === 2 && item.productType === (typeOfApp === 'secondLife' ? 0 : 1)).sort((a, b) => (a.id > b.id ? -1 : 1));

  useEffect(() => {
    fetch(`${config.baseUrl}/v1/products/all/stores`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
        "x-api-key": config.apiKey,
      },
      credentials: "same-origin",
    })
      .then((response) => response.json())
      .then((res) => {
        setPrintLabelData(res.data);
      })
      .catch((error) => console.log(error));

    if (!userAccessToken?.data?.access_token) {
      redirect("/");
    }
  }, []);

  useEffect(() => {
    if (selectAll) {
      const allProductIds = printLabelData
        .filter((item) => item.status === 2)
        .map((product) => product.id);
      setSelectedProducts(allProductIds);
    } else {
      setSelectedProducts([]);
    }
  }, [selectAll, printLabelData]);


  useEffect(() => {
    if (filterData) {
      const uniqueData = filterData.reduce((accumulator, item) => {
        const isExisting = accumulator.find(obj => obj.modelCode === item.modelCode);
        if (!isExisting) {
          accumulator.push(item);
        }
        return accumulator;
      }, []);

      setUniqRes(uniqueData);
    }

  }, []);



  useEffect(() => {

    if (uniqRes.length > 0 && !fetchFlag) {
      setFetchFlag(true);
      Promise.all(uniqRes.map((item, index) =>
        fetch(`${config.baseUrl}/v1/products/details/${item.modelCode}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
            "x-api-key": config.apiKey,
          },
        })
          .then((response) => response.json())
          .then((res) => {
            return res.data;

          })
          .catch((error) => console.log(error))
      )).then((responses) => {
        setResponseArray(responses)
      })
        .catch((error) => console.log(error));
    }

  }, [uniqRes, fetchFlag]);

  useEffect(() => {

    // if (responseArray.length > 0) {
    //   if (
    //     responseArray.sort((a, b) => a - b).toString() !==
    //     JSON.parse(localStorage.getItem("printLabelProducts"))?.sort((a, b) => a - b).toString()
    //   ) {
    //     localStorage.setItem("printLabelProducts", JSON.stringify(responseArray));
    //   }
    // }
    if (responseArray.length) {
      if (
        JSON.stringify(responseArray) !== localStorage.getItem("printLabelProducts")
      ) {
        localStorage.setItem("printLabelProducts", JSON.stringify(responseArray));
      }
    }

  }, [responseArray]);


  const handleProductSelect = (productId) => {
    if (selectedProducts.includes(productId)) {
      setSelectedProducts(selectedProducts.filter((id) => id !== productId));
    } else {
      setSelectedProducts([...selectedProducts, productId]);
    }
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
  };

  const handleOpenCheckBox = () => {
    setOpenCheckBox(!openCheckBox);
  };

  const handlePrintSelected = () => {
    const selectedProductIdNo = printLabelData
      .filter((product) => selectedProducts.includes(product.id))
      .map((product) => product.id);

    const promises = selectedProductIdNo.map((product) =>
      fetch(`${config.baseUrl}/v1/products/${product}/status?statusCode=4`, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
          'x-api-key': config.apiKey,
          "Content-Type": "application/json"
        },
        credentials: 'same-origin',
      })
        .then((response) => response.json())
        .catch((error) => {
          console.error(error);
        })
    );

    Promise.all(promises)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {

        console.error('Bir hata oluştu', error);
      });

    fetch(`${config.baseUrl}/v1/products/print?ids=${selectedProductIdNo[0]}`, {
      body: JSON.stringify(selectedProductIdNo),
      method: 'POST',
      headers: {
        Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
        'x-api-key': config.apiKey,
        "Content-Type": "application/json"
      },
      credentials: 'same-origin'
    })
      .then((response) => {
        if (response.ok) {
          window.location.reload();
          alert('Printing successful!');
        } else {
          alert('Something went wrong. Try again');
        }
      })
      .catch((error) => {
        console.error('Hata:', error);
      });

  };





  return (
    <div className="w-full h-full mt-[3.4em]" style={{ background: "#fff" }}>
      <div className="flex justify-between md:w-[33%] md:m-auto">
        <button className="fixed z-20 top-3 right-3" onClick={handleOpenCheckBox}>
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.99984 23.6379H1.99984C1.64622 23.6379 1.30708 23.4974 1.05703 23.2473C0.80698 22.9973 0.666504 22.6581 0.666504 22.3045V8.97119C0.666504 8.61757 0.80698 8.27843 1.05703 8.02838C1.30708 7.77833 1.64622 7.63786 1.99984 7.63786H5.99984V2.30452C5.99984 1.9509 6.14031 1.61176 6.39036 1.36172C6.64041 1.11167 6.97955 0.971191 7.33317 0.971191H20.6665C21.0201 0.971191 21.3593 1.11167 21.6093 1.36172C21.8594 1.61176 21.9998 1.9509 21.9998 2.30452V7.63786H25.9998C26.3535 7.63786 26.6926 7.77833 26.9426 8.02838C27.1927 8.27843 27.3332 8.61757 27.3332 8.97119V22.3045C27.3332 22.6581 27.1927 22.9973 26.9426 23.2473C26.6926 23.4974 26.3535 23.6379 25.9998 23.6379H21.9998V26.3045C21.9998 26.6581 21.8594 26.9973 21.6093 27.2473C21.3593 27.4974 21.0201 27.6379 20.6665 27.6379H7.33317C6.97955 27.6379 6.64041 27.4974 6.39036 27.2473C6.14031 26.9973 5.99984 26.6581 5.99984 26.3045V23.6379ZM5.99984 20.9712V19.6379C5.99984 19.2842 6.14031 18.9451 6.39036 18.695C6.64041 18.445 6.97955 18.3045 7.33317 18.3045H20.6665C21.0201 18.3045 21.3593 18.445 21.6093 18.695C21.8594 18.9451 21.9998 19.2842 21.9998 19.6379V20.9712H24.6665V10.3045H3.33317V20.9712H5.99984ZM8.6665 3.63786V7.63786H19.3332V3.63786H8.6665ZM8.6665 20.9712V24.9712H19.3332V20.9712H8.6665ZM4.6665 11.6379H8.6665V14.3045H4.6665V11.6379Z" fill="#D9DDE1" />
          </svg>
        </button>
        <label className={`${openCheckBox ? "block" : 'hidden'} " flex font-bold blue600 " `} >
          <input
            type="checkbox"
            checked={selectAll}
            onChange={handleSelectAll}
            className="mr-3 w-5 h-5 ml-2"
          />
          {t("SelectAll")}
        </label>
        <button className={`${openCheckBox ? "block" : 'hidden'} " blue600 mr-2 font-bold " `} onClick={handlePrintSelected}>{t("SendPrint")}</button>
      </div>
      {printLabelData
        .filter((item) => item.status === 2).length > 0 ? (printLabelData
          .filter((item) => item.status === 2 && item.productType === (typeOfApp === 'secondLife' ? 0 : 1))
          .map((product) => (
            <div className="flex items-center justify-center" key={product.id}>
              <input
                id={`list-radio-${product.id}`}
                type="checkbox"
                value=""
                name="list-radio"
                className={`${openCheckBox ? "block" : 'hidden'} " w-5 h-5 mx-3 "`}
                checked={selectedProducts.includes(product.id)}
                onChange={() => handleProductSelect(product.id)}
              />
              <SHPrintLabelCard
                productName={product.name}
                productBrand={product.brand}
                productColor={product.color}
                productRefNo={product.modelCode}
                productItemNo={product.itemCode}
                productPrice={product.finalPrice}
                productCategory={product.category}
                productSize={product.size}
                productGender={product.gender}
                productBarcode={product.barcode}
                productStatus={product.status}
                addedBy={product.addedBy}
                addedTime={product.addedTime}
                productCondition={product.condition}
                productSystemPrice={product.systemPrice}
                productDiscountRate={product.discountRate}
                id={product.id}
              />
            </div>
          ))) : <div className="z=[-20] text-center font-bold">Not Found...</div>}

    </div>
  );
};

export default SHPrintLabel;
